import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";

// import Image from "../../components/image";
// import styles from './index.module.css';
import { user, avatar, description, username, themename, excerpt, button, button2, button3 } from './index.module.css';

import {
  AboutWrapper,
} from './style';




// const User = props => (
// <div>
//   <Link to={props.url}>
//     <div className={user}>
//         <img src={props.avatar} className={avatar} alt="" />
//       <div className={description}>
//         <h3 className={username}><span className={themename}>{props.themename}</span>{props.username}</h3>
//         <p className={excerpt}>{props.excerpt}</p>
//       </div>
//     </div>
//   </Link>
// </div>
// )

const Jisyo = props => (
<div style={{width:"25%"}}>
  <a href={"/tags/"+props.title}>
    <div className={button3}>
      {/* <Image filename={props.src}></Image> */}
      <img style={{width:"20px",margin:"2px 5px 2px 2px"}} src={props.src}/>
       {props.title}
    </div>
  </a>
</div>
)

const Jisyo2 = props => (
<div style={{width:"25%"}}>
  {/* <a href={"/tags/"+props.title}> */}
    <div className={button2}>
      {/* <Image filename={props.src}></Image> */}
      <img style={{width:"20px",margin:"2px 5px 2px 2px",}} src={props.src}/>
       {props.title}
    </div>
  {/* </a> */}
</div>
)





const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};

 
const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
    <AboutWrapper>

<h1>
テーマ選択：「見える化」したいテーマを選びましょう
</h1>
<hr/>
<div>
    <div className={user}>
      <div>
        {/* <Image filename="/01SDGs.jpg"></Image> */}
        <StaticImage src="../../images/theme/01SDGs.jpg" alt="img" width={250}/>
      </div>
      <div className={description}>
        {/* <Link to="/sdgs"> */}
          <h3 className={username}><span className={themename}>SDGs</span><span>　　＊1・6・13・14・15番は準備中です。</span></h3>
        {/* </Link> */}

        <StaticImage src="../../images/sdgsicon/sdg_01.png" style={{width:"10%",margin:"2px"}} alt="img" />
        <a href="/tags/飢餓をゼロに"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_02.png" alt="img" /></a>
        <a href="/tags/すべての人に健康と福祉を"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_03.png" alt="img" /></a>
        <a href="/tags/質の高い教育をみんなに"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_04.png" alt="img" /></a>
        <a href="/tags/ジェンダー平等を実現しよう"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_05.png" alt="img" /></a>
        <StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_06.png" alt="img" />
        <a href="/tags/エネルギーをみんなにそしてクリーンに"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_07.png" alt="img" /></a>
        <a href="/tags/働きがいも経済成長も"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_08.png" alt="img" /></a>
        <a href="/tags/産業と技術革新の基盤をつくろう"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_09.png" alt="img" /></a>
        <a href="/tags/人や国の不平等をなくそう"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_10.png" alt="img" /></a>
        <a href="/tags/住み続けられるまちづくりを"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_11.png" alt="img" /></a>
        <a href="/tags/つくる責任つかう責任"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_12.png" alt="img" /></a>
        <StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_13.png" alt="img" />
        <StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_14.png" alt="img" />
        <StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_15.png" alt="img" />
        <a href="/tags/平和と公正をすべての人に"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_16.png" alt="img" /></a>
        <a href="/tags/パートナーシップで目標を達成しよう"><StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_17.png" alt="img" /></a>
        <StaticImage style={{width:"10%",margin:"2px"}} src="../../images/sdgsicon/sdg_all.png" alt="img" />

      </div>
    </div>
</div>




<div>
    <div className={user}>
    <StaticImage src="../../images/theme/02chirisougou.jpg" alt="img" width={250}/>
    {/* <img src="/02chirisougou.jpg" alt="image01" className={avatar} style={{width:"250px"}}/> */}
      <div className={description}>
        {/* <Link to="/chirisogo"> */}
          <h3 className={username}><span className={themename}>高校地理総合</span></h3>
        {/* </Link> */}

        <div style={{display:"flex"}}>
          <div className={button}>
            {/* <a href="/tags/地図や地理情報システム"> */}
              地図や地理情報システム
            {/* </a> */}
          </div>
          <div className={button}>
            <a href="/tags/地球的課題と国際協力">
              地球的課題と国際協力
            </a>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            {/* <a href="/chirisogo"> */}
              結びつきを深める現代世界
            {/* </a> */}
          </div>
          <div className={button}>
            {/* <a href="/chirisogo"> */}
              自然環境と防災
            {/* </a> */}
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            {/* <a href="/chirisogo"> */}
              生活文化の多様性と国際理解
            {/* </a> */}
          </div>
          <div className={button}>
            <a href="/tags/生活圏の調査と地域の展望">
              生活圏の調査と地域の展望
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>


<div>
    <div className={user}>
    <StaticImage src="../../images/theme/03chiritankyu.jpg" alt="img" width={250}/>
      <div className={description}>
        {/* <Link to="/chiritankyu"> */}
          <h3 className={username}><span className={themename}>高校地理探究</span></h3>
        {/* </Link> */}

        <div style={{display:"flex"}}>
          <div className={button}>
            {/* <a href="/chiritankyu"> */}
              自然環境
            {/* </a> */}
          </div>
          <div className={button}>
            <a href="/tags/生活文化，民族・宗教">
              生活文化，民族・宗教
            </a>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
          <a href="/tags/資源，産業">
              資源，産業
            </a>
          </div>
          <div className={button}>
            {/* <a href="/chiritankyu"> */}
              現代世界の地域区分
            {/* </a> */}
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            <a href="/tags/交通・通信，観光">
              交通・通信，観光
            </a>
          </div>
          <div className={button}>
            {/* <a href="/chiritankyu"> */}
              現代世界の諸地域
            {/* </a> */}
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            <a href="/tags/人口，都市・村落">
              人口，都市・村落
            </a>
          </div>
          <div className={button}>
            <a href="/tags/持続可能な国土像の探究">
              持続可能な国土像の探究
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>



<div>
    <div className={user}>
    <StaticImage src="../../images/theme/04chiritekijishou.jpg" alt="img" width={250}/>
      <div className={description}>
        {/* <Link to="/chirijisyo"> */}
          <h3 className={username}><span className={themename}>地理的事象</span></h3>
        {/* </Link> */}

        <div style={{display:"flex"}}>
          <Jisyo2 title="地形" src="/chiriicon/01_tikei.svg"/>
          <Jisyo2 title="気候" src="/chiriicon/02_kikou.svg"/>
          <Jisyo2 title="環境問題" src="/chiriicon/03_tikyuukannkyou.svg"/>
          <Jisyo title="交通" src="/chiriicon/04_koutuu.svg"/>
        </div>
        <div style={{display:"flex"}}>
          <Jisyo2 title="通信" src="/chiriicon/05_tuusin.svg"/>
          <Jisyo title="人口" src="/chiriicon/06_zinkou.svg"/>
          <Jisyo title="民族" src="/chiriicon/07_minzoku.svg"/>
          <Jisyo title="農業" src="/chiriicon/08_nougyou.svg"/>
        </div>
        <div style={{display:"flex"}}>
          <Jisyo2 title="林業" src="/chiriicon/09_ringyou.svg"/>
          <Jisyo2 title="水産業" src="/chiriicon/10_suisangyou.svg"/>
          <Jisyo2 title="資源エネルギー" src="/chiriicon/11_sigenn_enerugi.svg"/>
          <Jisyo2 title="鉱業" src="/chiriicon/12_kougyou.svg"/>
        </div>
        <div style={{display:"flex"}}>
          <Jisyo title="工業" src="/chiriicon/13_kougyou.svg"/>
          <Jisyo title="第３次産業" src="/chiriicon/14_dai3zisangyou.svg"/>
          <Jisyo title="労働" src="/chiriicon/15_roudou.svg"/>
          <Jisyo2 title="観光" src="/chiriicon/16_kankou.svg"/>
        </div>
        <div style={{display:"flex"}}>
          <Jisyo title="経済" src="/chiriicon/17_keizai.svg"/>
          <Jisyo2 title="貿易" src="/chiriicon/18_boueki.svg"/>
          <Jisyo2 title="国際機関" src="/chiriicon/19_kokusai.svg"/>
          <Jisyo2 title="世界の国々" src="/chiriicon/20_sekainokuniguni.svg"/>
        </div>

      </div>
    </div>
  </div>




  <div>
    <div className={user}>
    <StaticImage src="../../images/theme/05shutten.jpg" alt="img" width={250}/>
      <div className={description}>
        {/* <Link to="/chiritankyu"> */}
          <h3 className={username}><span className={themename}>出典</span></h3>
        {/* </Link> */}

        <div style={{display:"flex"}}>
          <div className={button}>
            <a href="/tags/国勢調査">
              国勢調査
            </a>
          </div>
          <div className={button}>
            <a href="/tags/経済センサス">
              経済センサス
            </a>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            <a href="/tags/商業統計調査">
              商業統計調査
            </a>
          </div>
          <div className={button}>
            <a href="/tags/国土数値情報">
              国土数値情報
            </a>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            <a href="/tags/農林業センサス">
              農林業センサス
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

    </AboutWrapper>

  );
};
 
export default App;



